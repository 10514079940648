import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Image from "../../composants/image/Image"
import LayoutTutorielFR from "../../composants/layout/fr/LayoutTutorielFR"
import Lien from "../../composants/navigation/Lien"
import Section from "../../composants/layout/Section"

import {
  obtenirImage,
  obtenirImages,
  obtenirPage,
  obtenirCartesLiensVersAutresPages,
} from "../../js/client-es/utils"
import fragmentsImages from "../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pagePaletteHubblePhotoshop: allPagesCompilationYaml(
      filter: { code: { eq: "paletteHubblePhotoshop" } }
    ) {
      ...FragmentPageYaml
    }
    pagesPourAllerPlusLoin: allPagesCompilationYaml(
      filter: {
        code: {
          in: [
            "paletteHubble12"
            "galerieNebuleusesPaletteHubble"
            "astrophotographiePhotoshop"
          ]
        }
      }
      sort: { fields: ordre_carte, order: ASC }
    ) {
      ...FragmentCarteLienVersPage
    }
    imagesPaysage: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "astrophotographieCCDLimagepresenteDesCouleursEquilibrees"
            "astrophotographieCCDAjustementdesCalquesDeReglage"
            "astrophotographieCCDMontagedeLimageCouleurDansPhotoshop"
            "astrophotographieCCDCouchecouleurTerminee"
            "astrophotographieCCDMontageH-SHO"
            "astrophotographieCCDImageHaFinale"
          ]
        }
      }
    ) {
      ...FragmentImagePaysage
    }
    imagesPaysageMoyen: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "ic4628Crevette"
            "astrophotographieCCDLesetoilesOntMaintenantDesCouleurs"
          ]
        }
      }
    ) {
      ...FragmentImagePaysageMoyen
    }
    imagesPaysagePetit: allImagesCompilationYaml(
      filter: { code: { in: ["astrophotographieCCDLecercleChromatique"] } }
    ) {
      ...FragmentImagePaysagePetit
    }
    imagesPaysageTresPetit: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "astrophotographieCCDLemodeleDeBohr-Rutherford"
            "astrophotographieCCDImageavecLeFiltreHa"
            "astrophotographieCCDImageavecLeFiltreSoufreSII"
            "astrophotographieCCDImageavecLeFiltreOxygeneOIII"
            "astrophotographieCCDBleuOK"
            "astrophotographieCCDRougeOK"
            "astrophotographieCCDVertOK"
            "astrophotographieCCDCoucheverte"
            "astrophotographieCCDCouchebleue"
            "astrophotographieCCDCoucherouge"
          ]
        }
      }
    ) {
      ...FragmentImagePaysageTresPetit
    }
  }
`

export default function PaletteHubblePhotoshop() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pagePaletteHubblePhotoshop")
  const pagesPourAllerPlusLoin = obtenirCartesLiensVersAutresPages(
    resultatsRequete,
    "pagesPourAllerPlusLoin"
  )
  const images = obtenirImages(
    resultatsRequete,
    "imagesPaysage",
    "imagesPaysageMoyen",
    "imagesPaysagePetit",
    "imagesPaysageTresPetit"
  )

  return (
    <LayoutTutorielFR
      page={page}
      pagesPourAllerPlusLoin={pagesPourAllerPlusLoin}
    >
      <Section titre="Introduction">
        <p>
          Avant de débuter votre lecture, si la palette de couleurs « Hubble »
          ne vous évoque rien, je vous invite à lire{" "}
          <Lien codePageCible="paletteHubble12">cet article</Lien>. Et si vous
          désirez en savoir encore plus, je vous invite à lire{" "}
          <Lien codePageCible="paletteHubble22">ce second article</Lien>.
        </p>
        <p>
          Les astrophotographies en palette « Hubble » sont peut-être les plus
          faciles à réaliser, et les résultats peuvent être spectaculaires.
        </p>
        <p>
          Ces images sont de plus en plus créées avec le logiciel Pixinsight.
          Mais pour ceux qui ne le possèdent pas, ou qui comme moi n’ont ni le
          temps ni l’envie de s’y mettre, voici une méthode de traitement avec
          Photoshop. Nous travaillerons sur une image de la nébuleuse de la
          Rosette, qui se prête très bien à un rendu en palette « Hubble ».
        </p>
        <Image
          image={obtenirImage(images, "ic4628Crevette")}
          langue={page.langue}
        />
      </Section>
      <Section titre="L’acquisition">
        <p>
          Si vous possédez une caméra CCD (inutile de faire ceci avec une caméra
          CMOS), réalisez vos acquisitions en « express » :{" "}
          <strong>
            seule l’acquisition avec le filtre Hα est réalisée en binning 1
          </strong>
          . Les autres acquisitions sont réalisées <strong>en binning 2</strong>{" "}
          (filtres [OIII] et [SII]).
        </p>
        <p>
          {" "}
          Il est possible de procéder ainsi car très souvent (mais pas tout le
          temps !) tous les détails des nébuleuses sont présents dans la couche
          Hα. Elle peut donc servir de luminance dans le montage final, alors
          que les images des filtres [SII] et [OIII] ne seront utilisées que
          pour la couleur.
        </p>
        <p>
          Il est donc bien plus intéressant de <strong>maximiser</strong> les
          temps d’acquisition avec le <strong>filtre Hα</strong> afin de
          travailler avec une luminance riche en signal.
        </p>
        <div className="conteneur-cote-a-cote">
          <Image
            image={obtenirImage(
              images,
              "astrophotographieCCDImageavecLeFiltreSoufreSII"
            )}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(
              images,
              "astrophotographieCCDImageavecLeFiltreHa"
            )}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(
              images,
              "astrophotographieCCDImageavecLeFiltreOxygeneOIII"
            )}
            langue={page.langue}
          />
        </div>
        <p>
          Exemple avec la nébuleuse de la Rosette :{" "}
          <strong>
            la couche Hα (à droite) contient à la fois les détails des couches
            [OIII] (à gauche) et [SII] (au milieu).
          </strong>{" "}
          Elle peut donc nous servir de <strong>luminance</strong>.
        </p>
        <p>
          Enfin, faites attention lors de l’acquisition de la couche [OIII] : à
          moins que vous ne disposiez d’un filtre à bande passante très étroite
          (3nm), celui-ci est très sensible à la pollution lumineuse et à la
          Lune.
        </p>
      </Section>
      <Section titre="Prétraitements">
        <p>
          Avant de vous jeter sur Photoshop, vous aurez bien sûr prétraité
          toutes vos images : retrait des darks, division par les flats,
          registration, etc.
        </p>
        <p>Je procède ainsi :</p>
        <ul>
          <li>
            <strong>Prétraitement</strong> dans votre logiciel de registration
            favori (Deep Sky Stacker, Pixinsight …) de la couche Hα.
          </li>
          <li>
            Si les acquisitions de vos couches [SII] et [OIII] ont été réalisées
            en binning 2 : empilez vos images unitaires{" "}
            <strong>en drizzle 2x</strong> : il faut les agrandir pour qu’elles
            aient la taille de l’image Hα. Le « drizzling » possède l’avantage
            d’être plus précis qu’un simple redimensionnement.
          </li>
          <li>
            <strong>Alignement</strong> (registration) des images de vos 3
            couches.
          </li>
        </ul>
      </Section>
      <Section titre="Le montage H-SHO">
        <p>
          <strong>Tous les détails se trouvant dans la couche Hα</strong>, nous
          réaliserons un montage de type « <strong>H-SHO</strong> ».
          C’est-à-dire que :
        </p>
        <ul>
          <li>
            Les images combinées des 3 filtres serviront à réaliser la couche
            couleur.
          </li>
          <li>La couche Hα servira de luminance.</li>
        </ul>
        <p>
          Pour réaliser notre traitement, nous procéderons donc en 3 étapes :
        </p>
        <ul>
          <li>1. Réalisation de la couche couleur.</li>
          <li>2. Réalisation de la luminance.</li>
          <li>3. Montage de l’image finale.</li>
        </ul>
      </Section>
      <Section titre="Réalisation de la couche couleur">
        <h3>Introduction</h3>
        <p>
          Une fois les images de chaque filtre prétraitées, nous pouvons débuter
          nos travaux pour créer la couche couleur. Les images que nous
          traiterons ici ne serviront pas à réaliser de luminance, il ne faut
          pas chercher à accentuer les détails, monter le contraste… Ne cherchez
          pas non plus une esthétique parfaite de vos couleurs, car elles seront
          modifiées quand vous apporterez la luminance.
        </p>
        <p>
          L’objectif est d’obtenir une bonne base de travail pour les retouches
          esthétiques finales.
        </p>
        <h3>Traitement des images de chaque couche</h3>
        <p>Vous devez traiter chacune de vos images de façon très homogène :</p>
        <ul>
          <li>
            Le niveau du noir doit être semblable (mesurez le niveau du fond de
            ciel à l’aide de la pipette) ;
          </li>
          <li>
            La taille des étoiles doit être semblable (faites une réduction
            d’étoiles si besoin, surtout sur la couche [OIII]) ;
          </li>
          <li>
            La « luminosité » générale des images des 3 filtres doit être
            semblable.
          </li>
        </ul>
        <p>
          Voici ce que j’obtiens avec les 3 couches de la nébuleuse de la
          Rosette :
        </p>
        <div className="conteneur-cote-a-cote">
          <Image
            image={obtenirImage(
              images,
              "astrophotographieCCDImageavecLeFiltreSoufreSII"
            )}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(
              images,
              "astrophotographieCCDImageavecLeFiltreHa"
            )}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(
              images,
              "astrophotographieCCDImageavecLeFiltreOxygeneOIII"
            )}
            langue={page.langue}
          />
        </div>
        <p>
          Encore une fois, il est inutile de vous préoccuper des détails à cette
          étape (resserrez et équilibrez les niveaux). Ils seront apportés par
          la luminance que nous traiterons après. Si une des images est bruitée,
          n’hésitez pas à la flouter un peu (avec un flou gaussien de rayon 1 ou
          2 par exemple).
        </p>
        <h3>Rappel : les calques de réglages</h3>
        <p>
          Dans Photoshop, les calques de réglages vous permettent de réaliser
          des réglages sur votre image (niveaux, courbes, saturation…) sous
          forme de calques.
        </p>
        <p>Ils ont deux avantages majeurs :</p>
        <ul>
          <li>
            Ils sont <strong>non destructifs</strong> (vous pouvez supprimer le
            calque pour supprimer le réglage et ainsi retrouver l’image
            d’origine) ;
          </li>
          <li>
            Ils conservent les <strong>paramètres de votre réglage</strong> et
            vous permettent de les modifier <strong>ultérieurement</strong>.
          </li>
        </ul>
        <h3>Rappel : les masques d’écrêtage</h3>
        <p>
          Dans Photoshop, un masque d’écrêtage est un masque dont l’effet ne
          s’applique <strong>qu’au calque qui est en dessous de lui</strong>.
          Les autres calques ne seront pas touchés.
        </p>
        <p>
          Le raccourci clavier pour créer un masque d’écrêtage est Ctrl + Alt +
          G (ou « Calques &gt; Créer un masque d’écrêtage »).
        </p>
        <h3>Le cercle chromatique</h3>
        <p>
          « Un cercle chromatique est une représentation ordonnée des couleurs,
          présentées dans l’ordre de celles de l’arc-en-ciel (…), utilisée en
          peinture, en teinturerie, en design industriel, en mode, en arts
          graphiques. » (Wikipédia)
        </p>
        <Image
          image={obtenirImage(
            images,
            "astrophotographieCCDLecercleChromatique"
          )}
          langue={page.langue}
        />
        <h3>Création de l’image couleur</h3>
        <p>
          Dans Photoshop, créez une nouvelle image RVB. Dans un montage « SHO »,
          le soufre correspond au rouge (0° sur le cercle chromatique).
        </p>
        <p>Pour cela :</p>
        <ul>
          <li>1. Copiez l’image de la couche [SII] dans la nouvelle image.</li>
          <li>
            2. Créez un calque de réglage de type « teinte – saturation ».
          </li>
          <li>
            3. Dans la boîte de dialogue qui s’ouvre, cliquez sur « Redéfinir ».
            Saisissez les valeurs suivantes : teinte = 0, saturation = 100,
            luminosité = -50.
          </li>
          <li>
            4. Appliquez un masque d’écrêtage au calque : raccourci clavier Ctrl
            + Alt + g.
          </li>
        </ul>
        <Image
          image={obtenirImage(
            images,
            "astrophotographieCCDMontagedeLimageCouleurDansPhotoshop"
          )}
          langue={page.langue}
        />
        <p>
          Puis répétez le processus pour les couches suivantes.{" "}
          <strong>
            Attention, le mode de fusion des autres calques (Hα et [OIII]) doit
            être « éclaircir » :
          </strong>
        </p>
        <ul>
          <li>Hα : teinte = 120 (vert), saturation = 100, luminosité = -50.</li>
          <li>
            [OIII] : teinte = 240 (bleu), saturation = 100, luminosité = -50.
          </li>
        </ul>
        <p>Vous obtenez ceci :</p>
        <Image
          image={obtenirImage(
            images,
            "astrophotographieCCDAjustementdesCalquesDeReglage"
          )}
          langue={page.langue}
        />
        <p>
          L’image est assez verte. C’est normal, car la couche Hα est plus
          intense que toutes les autres.
        </p>
        <h3>Traitement de l’image couleur</h3>
        <p>
          Dupliquez votre montage pour ne pas le perdre (il vous sera sans
          doutes utile plus tard) puis aplanissez-le.
        </p>
        <p>
          Vous devez commencer par vérifier les histogrammes et{" "}
          <strong>équilibrer les couches R,V,B</strong> : même « point zéro»,
          répartition assez similaire des intensités lumineuses. Ne vous
          préoccupez pas encore du halo magenta des étoiles.
        </p>
        <p>
          Si les couches sont bien équilibrées,{" "}
          <strong>
            vous devez commencer à distinguer les 4 couleurs de votre image
            finale
          </strong>{" "}
          : du vert, du bleu, du rouge et du jaune. Si ce n’est pas le cas, vos
          couches ne sont sans doute pas équilibrées, inutile d’aller plus loin.
        </p>
        <Image
          image={obtenirImage(
            images,
            "astrophotographieCCDLimagepresenteDesCouleursEquilibrees"
          )}
          langue={page.langue}
        />
        <p>
          On commence à distinguer les différentes couleurs de la future image.
          Les histogrammes des 3 couches sont semblables :
        </p>
        <div className="conteneur-cote-a-cote">
          <Image
            image={obtenirImage(images, "astrophotographieCCDCoucherouge")}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(images, "astrophotographieCCDCoucheverte")}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(images, "astrophotographieCCDCouchebleue")}
            langue={page.langue}
          />
        </div>
        <p>
          Occupons-nous maintenant de la dominante magenta des étoiles :
          Rendez-vous dans « Image &gt; Réglages &gt; Correction sélective ».
          Choisissez la couche magenta, puis diminuez le curseur « Magenta ».
          Vos étoiles devraient en plus prendre un peu de couleurs.
        </p>
        <Image
          image={obtenirImage(
            images,
            "astrophotographieCCDLesetoilesOntMaintenantDesCouleurs"
          )}
          langue={page.langue}
        />
        <p>
          Vérifiez <strong>tout de suite</strong> les histogrammes pour vous
          assurer de ne pas avoir fait de bêtises. Si une couche est{" "}
          <strong>déséquilibrée, remontez dans l’historique</strong> pour
          trouver où l’erreur a été commise.
        </p>
        <p>
          Continuons. Il reste <strong>deux défauts </strong>dans l’image :
        </p>
        <ul>
          <li>
            1. Bien que les couches soient équilibrées, elle est encore trop
            verte ;
          </li>
          <li>
            2. Les couleurs « cuivrées » typiques de la palette Hubble ne sont
            pas encore présentes.
          </li>
        </ul>
        <p>
          Pour remédier à ces défauts, rendez-vous à nouveau dans « Image &gt;
          Réglages &gt; Correction sélective ».
        </p>
        <ul>
          <li>Sur le vert : diminuez le cyan et le magenta.</li>
          <li>
            Sur le jaune : diminuez le cyan et augmentez légèrement le magenta.
          </li>
          <li>Sur le cyan : diminuez le jaune.</li>
        </ul>
        <p>
          Vous pouvez réaliser plusieurs passes, et essayer de toucher aux
          autres couleurs.
        </p>
        <p>
          Mais faites très <strong>attention</strong> : cet outil a la fâcheuse
          tendance à produire{" "}
          <strong>de violentes transitions de couleurs</strong> si vous poussez
          trop les curseurs (exemple : passage d’une zone verte à une zone
          bleue, sans dégradé intermédiaire). <strong>Visualisez</strong>{" "}
          régulièrement votre image à <strong>100%</strong> pour vérifier
          qu’aucun dégât n’a été fait.
        </p>
        <p>
          Pensez à vérifier vos niveaux après vos manipulations. Puis, après une
          réduction d’étoiles et en y allant petit à petit sur la correction
          sélective et les niveaux, j’obtiens ceci :
        </p>
        <Image
          image={obtenirImage(
            images,
            "astrophotographieCCDCouchecouleurTerminee"
          )}
          langue={page.langue}
        />
        <p>
          Appliquez éventuellement une réduction d’étoiles pour éviter des halos
          quand vous assemblerez votre couleur avec la luminance Hα. Aussi, si
          votre image couleur est bruitée, vous pouvez appliquer un léger flou
          gaussien.
        </p>
        <p>
          <strong>Ne cherchez pas encore une esthétique parfaite</strong> car
          les couleurs seront modifiées lorsque vous apporterez la{" "}
          <strong>luminance</strong>. S’il reste une dominante verte, vous
          pourrez la corriger lors des retouches esthétiques finales. L’objectif
          est d’avoir une bonne base pour pouvoir réaliser des travaux
          esthétiques plus lourds en fin de traitement.
        </p>
        <h3>Validation de la couche couleur</h3>
        <p>Votre image couleur est prête si :</p>
        <ul>
          <li>
            1. Vous avez 3 couleurs principales dans votre image : du rouge /
            jaune, du vert (un peu mais pas trop), et du bleu.
          </li>
          <li>
            2. Votre image possède des couleurs qui sont équilibrées. Il ne faut
            pas de couleur dominante, ni de couleur qui soit éteinte. Pour cela,
            consultez très régulièrement l’histogramme ;
          </li>
          <li>
            3. Vos étoiles ne sont pas trop grosses, n’ont pas de halos sombres
            inesthétiques, et surtout (le plus important), ont des couleurs
            différentes.
          </li>
        </ul>
        <p>
          Si la première règle est subjective, les 2 dernières règles sont
          purement objectives et{" "}
          <strong>doivent vous servir d’indicateurs</strong>. Si une de ces
          règles n’est pas validée, alors il y a eu un problème lors de votre
          traitement. Recherchez l’étape qui a créé les problèmes, et reprenez
          votre traitement à partir de là.
        </p>
        <p>Vérification des histogrammes sur mon image couleur finale :</p>
        <div className="conteneur-cote-a-cote">
          <Image
            image={obtenirImage(images, "astrophotographieCCDRougeOK")}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(images, "astrophotographieCCDVertOK")}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(images, "astrophotographieCCDBleuOK")}
            langue={page.langue}
          />
        </div>
        <p>
          Les 3 couches sont équilibrées. En analysant les histogrammes, on
          détecte toutefois une légère dominante verte. Elle se corrigera
          facilement plus tard.
        </p>
      </Section>
      <Section titre="Montage final">
        <h3>Traitement de la luminance</h3>
        <p>
          <strong>La couche Hα nous sert de luminance</strong> car elle contient
          tous les <strong>détails</strong> de l’image finale. Traitez-la comme
          à votre habitude.
        </p>
        <p>
          Une règle toutefois :{" "}
          <strong>les traitements les plus simples</strong> sont souvent{" "}
          <strong>les meilleurs</strong>. Pour quelques astuces de traitements
          dans Photoshop, jetez un œil à{" "}
          <Lien codePageCible="astrophotographiePhotoshop">cet article</Lien>.
        </p>
        <Image
          image={obtenirImage(images, "astrophotographieCCDImageHaFinale")}
          langue={page.langue}
        />
        <h3>Réalisation du montage</h3>
        <p>
          Pour obtenir une première version de l’image, il nous reste à coller
          la luminance sur l’image couleur en mode « Luminosité » :
        </p>
        <Image
          image={obtenirImage(images, "astrophotographieCCDMontageH-SHO")}
          langue={page.langue}
        />
        <p>
          Votre image est presque terminée. Vous disposez d’une bonne base
          (étoiles colorées, des nuances dans toutes les couleurs, couleurs
          équilibrées) pour apporter des corrections esthétiques finales.
          Diminuez les restes de la dominante verte. Corrigez les couleurs à
          votre goût avec l’outil de correction sélective (attention, ne poussez
          pas trop les curseurs), augmentez la saturation…
        </p>
        <p>
          Enfin, terminez votre travail en fusionnant votre luminance et votre
          couche couleur.
        </p>
        <h3>Un montage H-HSHO</h3>
        <p>
          Pour augmenter la saturation des couleurs de votre image sans faire
          monter le bruit, voici la très efficace technique de Robert Gendler :
        </p>
        <ul>
          <li>
            Floutez l’image H-SHO que vous venez de réaliser : elle vous servira
            de nouvelle couche couleur.
          </li>
          <li>Augmentez la saturation des couleurs, etc.</li>
          <li>Sur cette nouvelle image couleur, collez votre luminance Hα.</li>
          <li>Bien sûr, contrôlez vos niveaux après l’opération.</li>
        </ul>
      </Section>
    </LayoutTutorielFR>
  )
}
